const stripeFormBank = () => {

  const stripe = Stripe(_stripe_public_key);
  // Create a source or display an error when the form is submitted.
  const form = document.getElementById('stripe');


  form.addEventListener('submit', async (event) => {
      event.preventDefault();
      // Gather additional customer data we may have collected in our form.
      var account_holder_name = $("#bank_account_account_holder_name").val()
      var account_number = $("#bank_account_account_number").val()
      var routing_number = $("#bank_account_routing_number").val()

      var bank_info = {
        country: 'US',
        currency: 'usd',
        routing_number: routing_number,
        account_number: account_number,
        account_holder_name: account_holder_name,
        account_holder_type: 'individual',
      }

      const {token, error} = await stripe.createToken('bank_account', bank_info);
      if (error) {
        // Inform the customer that there was an error
        $('.plaidResponse').addClass('alert-error')
        $('.plaidResponse').removeClass('hidden')
        $('#plaidResponseMessage').text(error.message)
      } else {
        // Send the source to your server
        stripeTokenHandler(token);
      }
  });
}

const stripeTokenHandler = (token) => {
  // Insert the source ID into the form so it gets submitted to the server
  const form = document.getElementById('stripe');
  const hiddenInput = document.createElement('input');
  hiddenInput.setAttribute('type', 'hidden');
  hiddenInput.setAttribute('name', 'stripeToken');
  hiddenInput.setAttribute('value', token.id);
  form.appendChild(hiddenInput);

  // Submit the form
  form.submit();
}

export default stripeFormBank