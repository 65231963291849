const stripeFormCard = () => {

  const stripe = Stripe(_stripe_public_key);
  const elements = stripe.elements();
  const card = elements.create('card');

  card.mount('#stripeCard');

  card.addEventListener('change', ({error}) => {
    const displayError = document.getElementById('stripeCardErrors');
    if (error) {
      displayError.textContent = error.message;
      displayError.style.display = 'block';
    } else {
      displayError.textContent = '';
      displayError.style.display = 'none';
      $(".btn").prop("disabled",false);
    }
  });

  // Create a source or display an error when the form is submitted.
  const form = document.getElementById('stripe');


  form.addEventListener('submit', async (event) => {
    if (card["_empty"] === false) {
      event.preventDefault();
      // Gather additional customer data we may have collected in our form.
      var name = $("[id$=name_on_card]").val()
      var cardInfo = {
          name: name
      }

      const {token, error} = await stripe.createToken(card, cardInfo);
      if (error) {
        // Inform the customer that there was an error
        const errorElement = document.getElementById('stripeCardErrors');
        errorElement.textContent = error.message;
        errorElement.style.display = 'block';
      } else {
        // Send the source to your server
        stripeTokenHandler(token);
      }
    }
  });
}

const stripeTokenHandler = (token) => {
  // Insert the source ID into the form so it gets submitted to the server
  const form = document.getElementById('stripe');
  const hiddenInput = document.createElement('input');
  hiddenInput.setAttribute('type', 'hidden');
  hiddenInput.setAttribute('name', 'stripeToken');
  hiddenInput.setAttribute('value', token.id);
  form.appendChild(hiddenInput);
  form.submit();
}

export default stripeFormCard