import toggle_visibility from './toggle_visibility';
import select2 from 'select2';
import babelpolyfill from 'babel-polyfill';
import stripeFormCard from './stripe_form_card'
import stripeFormBank from './stripe_form_bank'

const init = () => {
  console.log("Init loaded")
  $(document).ready(function () {

    // Start Google Analytics event definitions
    $('form#log_in').submit(function (ev) {
      gtag('event', 'login', {
        method: 'Login'
      });
    });

    $('form#register').submit(function (ev) {
      gtag('event', 'sign_up', {
        method: 'Register'
      });
    });

    $('form#review-one-time').submit(function (ev) {
      gtag('event', 'donations_new_one_time_donation', {
        'event_category': 'donations',
        'event_label': 'new one time donantion'
      });
    });

    $('form#review-recurring-donation').submit(function (ev) {
      gtag('event', 'donations_new_recurring_donation', {
        'event_category': 'donations',
        'event_label': 'new recurring donantion'
      });
    });

    $("#stripe").on("keypress", function (e) {
      if (e.keyCode == 13) {
        return false;
      }
    });


    // End Google Analytics event definitions

    if ($('.credit_card-new').length !== 0) {
      stripeFormCard()
    }

    if ($('.bank_account-new').length !== 0) {
      stripeFormBank()
    }

    if ($('.credit_card-create').length !== 0) {
      stripeFormCard()
    }

    $('#cardModal').on('show.bs.modal', function (event) {
      $("[id$=user_payment_profile_type]").prop('value', 'CreditCard')
      stripeFormCard()
    })

    $('.select2').select2({
      width: '100%',
      theme: "bootstrap",
    })

    $("#year-filter")
      .change(function () {
        window.location.href = "/history/" + this.value;
      })

    $(".payment-type-input")
      .change(function () {
        toggle_visibility(this.value, '-information')
      })


    $("#where-its-most-needed-select")
      .click(function () {
        $('#sponsorship-preferences').hide()
        $('#sponsorship_use_preferences').prop('value', '0')
        $("#where-its-most-needed-select").addClass('selected')
        $("#based-on-preferences-select").removeClass('selected')
      })

    $("#based-on-preferences-select")
      .click(function () {
        $('#sponsorship-preferences').show()
        $('#sponsorship_use_preferences').prop('value', '1')
        $("#where-its-most-needed-select").removeClass('selected')
        $("#based-on-preferences-select").addClass('selected')
      })

    $('#amount-select').change(function () {
      const value = $('#amount-select').val()
      if (value === 'other') {
        $('#recurring_donation_amount').prop('type', 'number')
        $('#recurring_donation_amount').addClass('form-control numeric decimal')
        $('#hidden-label').show();
      } else {
        $('#recurring_donation_amount').prop('value', value)
      }
    }).change();

    $('#prefModal').on('show.bs.modal', function (event) {
      var button = $(event.relatedTarget)
      var id = button.data('childid')
      var modal = $(this)
      modal.find('#sponsorship_child_id').val(id)
    })

    $("#tax-year-filter")
      .change(function () {
        window.location.href = "/tax-information/" + this.value;
      })

    $('#one_time_donation_mailing_address_id, #recurring_donation_mailing_address_id')
      .change(function () {
        if (this.value !== "") {
          $('#contact-information-fields').hide();
        } else {
          $('#contact-information-fields').show();
        }
      })

    $('#one_time_donation_user_payment_profile_id, #recurring_donation_user_payment_profile_id')
      .change(function () {
        if (this.value !== "") {
          $('#payment-information-fields').hide();
        } else {
          $('#payment-information-fields').show();
        }
      })
  })
}

export default init;