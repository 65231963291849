const toggle_visibility = (value, pattern) => {
  value = value.replace(/\s/g,'')
  $("[id$="+pattern+"]").hide()  
  $("[id$="+pattern+"]").find('input').each(function(i) { 
    $(this).prop('required',false);
  });
  $('#'+value+pattern).show()
  $('#'+value+pattern).find('input').each(function(i) { 
    $(this).prop('required',true);
  });
}

export default toggle_visibility;